require('../packs/application')

import Vue from 'vue/dist/vue.esm'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

$(function(){
    function getValue(id: string): string {
        const input = document.getElementById(id) as HTMLInputElement | null;
        if (input == null) return '';
        return input.value;
    }

    if ($('#vue-belonging-group-switcher').length) {
        // 選択肢データ
        let groupsOptions = [[0,'- 組織なし -']]

        const belonging_groups = getValue('__belonging_groups');
        if (belonging_groups!=null) {
            const x = JSON.parse(belonging_groups)
            groupsOptions.push(...x)
        }

        // { id:, name: } な連想配列に変換
        const groups = groupsOptions.map(g => { return {id: g[0], name: g[1]}; })
        // console.log({belonging_groups, groupsOptions})

        // 初期選択させる選択肢
        const initialSelectedGroupId = getValue('__current_group_id');
        const selectedGroup = groups.find(x => x.id == initialSelectedGroupId)

        new Vue({
            el: '#vue-belonging-group-switcher',
            components: {
                Multiselect,
            },
            data() {
                return {
                    groups: groups,
                    selectedGroup: selectedGroup
                }
            },
            methods: {
                onSelected(group) {
                    // TODO 排他制御
                    // TODO ローディング表示
                    $.post('/groups/switch_current_group', { group_id: group.id })
                        .then(() => {
                            location.reload() // 画面リロード
                        })
                },
            }
        });
    }
});
