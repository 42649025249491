// このプロジェクトではコントローラごとのJSファイルを個別に作成し、
// 共通処理としてこのファイルを require する構造とする。
// 業務ロジックはこのファイルに書かないこと。

// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Style Sheets
import '../stylesheets/application'

require('admin-lte')
require('admin-lte/node_modules/bootstrap/dist/js/bootstrap.min')
// import '@fortawesome/fontawesome-free/js/all'  // fontawesome は CDN版を使用する

// jQuery に依存するコードは、ここより下に書くこと。AdminLTEをロードすることでjQueryもロードされるため。
require("@rails/ujs").start()

require('../src/toaster_notification')
require('../src/cancel_button_to_close')

require('../src/beloging_group_switcher')

require('../src/ajax_error_handling')

