const toastr = require('toastr')

$(function() {
  toastr.options.timeOut = 3000;
  toastr.options.positionClass = "toast-top-center";

  $("#notifications p").each(function () {
    if ($(this).hasClass("notice") || $(this).hasClass("success")) {
      toastr.success($(this).text());
    } else {
      toastr.options.timeOut = 20000;
      toastr.options.closeButton = true;
      toastr.error($(this).text());
    }
  });
});
