$(function(){
    if (typeof window.sessionStorage === 'undefined') return;

    // セッションストレージ（タブスコープ）に history.length の初期値を保存しておき、
    // 現在の値と比較して異なっていれば処理させない。
    // 新規タブを開いた時の history.length の初期値はブラウザごとに異なるため。
    const key = 'initialHistoryLength';
    const current = history.length.toString();
    const initialLength = window.sessionStorage.getItem(key);
    //console.log("current=", current, "initialLength=", initialLength);  // debug
    if (initialLength !== null && initialLength !== current) {
        // セッションストレージに値があって、初期値と異なる場合は、処理対象外
        return;
    }

    window.sessionStorage.setItem(key, current);  // セッションストレージにセット

    // キャンセルボタンを閉じるボタンに変更する
    const cancelButton = $('.btn-cancel-or-close');
    cancelButton.html('<i class="fas fa-times"></i> 閉じる');
    cancelButton.on('click', function() {
        window.close();
    });
});
