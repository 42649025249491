$(() => {
    $(".loading").bind("ajaxSend", () => {
        $(this).show();
    }).bind("ajaxComplete", () => {
        $(this).hide();
    });

    // jQuery の Ajaxエラー発生時のデフォルトエラーハンドラを仕掛ける
    $(document).ajaxError((event, xhr, settings, thrownError) => {
        const log = {
            type: settings.type,
            url: settings.url,
            message: xhr.status >= 500 ? 'サーバーエラーが発生しました。' : '通信エラーが発生しました。'
        }
        if (xhr) {
            log.status = xhr.status;
            log.response = xhr.responseJSON;
        }
        console.log(log)
        if ($('#dont-show-default-ajax-error-alert').length == 0) {
            alert(log.message);
        }
    });
});